import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Breadcrumbs,
  Container,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Hidden,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
import { Link } from "react-router-dom";
import address from "../assets/img/address.png";
import Newsletter from "../components/Newsletter.jsx";
import Consultation from "../components/Consultation";
import hand from "../assets/img/hand.svg";

export default function Contact() {
  const useStyles = makeStyles(theme => ({
    termsText: {
      fontSize: 14,
      marginTop: 20
    },
    mt5: {
      marginTop: theme.spacing(5)
    },
    formControl: {
      margin: theme.spacing(0, 0, 4),
      "@media (max-width:599px)": {
        margin: theme.spacing(0, 0, 2)
      }
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    contactBanner: {
      marginTop: "-64px",
      background: theme.palette.secondary.main,
      padding: theme.spacing(20, 0),
      "& span": {
        color: theme.palette.primary.main
      }
    },
    mt8: {
      marginTop: theme.spacing(8)
    },
    // addressBlock
    addressBlock: {
      padding: theme.spacing(10, 0)
    },
    iframe: {
      filter: "Grayscale(1)"
    },
    contactLinks: {
      fontSize: 14,
      display: "block",
      color: " #79787d"
    },
    inquiryLinks: {
      fontWeight: 500,
      color: theme.palette.primary.main,
      display: "block",
      textDecoration: "underline",
      marginBottom: 5
    },
    // inquiry
    inquiry: {
      padding: theme.spacing(10, 0),
      "& h3": {
        marginBottom: 10
      },
      "& p": {
        color: "#79787d",
        "& a": {
          color: theme.palette.primary.main
        }
      }
    },
    "@media (max-width:1366px)": {
      contactBanner: {
        padding: theme.spacing(10, 0)
      }
    },
    "@media (max-width:959px)": {
      addressBlock: {
        padding: theme.spacing(5, 0)
      },
      contactLinks: {
        fontSize: 12
      },
      inquiry: {
        padding: theme.spacing(5, 0, 10)
      }
    },
    "@media (max-width:599px)": {
      contactBanner: {
        padding: theme.spacing(5, 0)
      },
      mt8: {
        marginTop: theme.spacing(4)
      },
      inquiry: {
        padding: theme.spacing(0, 0, 5, 0),
        "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
          padding: theme.spacing(1, 2)
        }
      }
    }
  }));
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  function getData(e) {
    e.preventDefault();
    var data = {
      name: name,
      phone: phone,
      email: email,
      message: message,
      query: values.query
    };
    fetch("https://dhaug2017-338.000webhostapp.com/dhrupapi/dhrupcontact.php", {
      method: "POST",
      body: JSON.stringify(data)
    });
  }
  const [values, setValues] = React.useState({
    query: "Inquiry",
    Inquiry: true,
    Schedule: false,
    Estimate: false
  });
  function handleChange(event) {
    setValues(oldValues => ({
      ...oldValues,
      [event.target.name]: event.target.value
    }));

    if (event.target.value === "Inquiry") {
      setValues(oldValues => ({
        ...oldValues,
        Inquiry: true,
        Schedule: false,
        Estimate: false
      }));
    } else if (event.target.value === "Schedule") {
      setValues(oldValues => ({
        ...oldValues,
        Inquiry: false,
        Schedule: true,
        Estimate: false
      }));
    } else {
      setValues(oldValues => ({
        ...oldValues,
        Inquiry: false,
        Schedule: false,
        Estimate: true
      }));
    }
  }
  const classes = useStyles();
  return (
    <React.Fragment>
      {/* banner */}
      <div className={classes.contactBanner}>
        <Container>
          <Grid container>
            <Grid item sm={8}>
              <Typography variant="h1">
                A simple <span>“Hello”</span> can change things. <br />
                Let’s open doors of possibilities.
              </Typography>
              <Typography variant="subtitle2">
                Can you recall the last time you were successful in saying
                “Hello”, Let’s make it successful.
              </Typography>
              <Breadcrumbs aria-label="breadcrumb" className={classes.mt8}>
                <Link to="/">Home</Link>
                <Link to="/">Pages</Link>
                <Typography color="textPrimary">Contact</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item sm={4}>
              <img src={hand} className={classes.svgImg} alt="..." />
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* Address */}
      <div className={"location " + classes.addressBlock}>
        <Container>
          <Grid container>
            <Grid item sm={7} xs={12}>
              <iframe
                className={classes.iframe}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.5939986952158!2d-117.78757748448595!3d33.719453142640695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcdc7d41834fcf%3A0x112e03acf98f2917!2s432%20Wycliffe%2C%20Irvine%2C%20CA%2092602%2C%20USA!5e0!3m2!1sen!2sin!4v1569228628973!5m2!1sen!2sin"
                width="90%"
                height="400"
                frameBorder="0"
                title="Map"
              ></iframe>
            </Grid>
            <Grid item sm={5} xs={12}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={address} alt="address" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Irvine, USA"
                    secondary="432 wycliffe irvine,CA 92602, USA"
                  />
                </ListItem>
              </List>
              <Grid container>
                <Grid item md={6} sm={12} xs={6}>
                  <Box component="h4" mb={1}>
                    Email
                  </Box>
                  <a
                    className={classes.contactLinks}
                    href="mailto:hello@dhrup-com-948446.hostingersite.com"
                  >
                    hello@dhrup-com-948446.hostingersite.com
                  </a>
                  <a
                    className={classes.contactLinks}
                    href="mailto:business@dhrup-com-948446.hostingersite.com"
                  >
                    business@dhrup-com-948446.hostingersite.com
                  </a>
                </Grid>
                <Grid item md={6} sm={12} xs={6}>
                  <Box component="h4" mb={1}>
                    Call us
                  </Box>
                  <a
                    className={classes.contactLinks}
                    href="tel:(91) 479-642-7461"
                  >
                    Phone: (91) 479-642-7461
                  </a>
                </Grid>
              </Grid>
              <Box mt={5} className="inqLinks">
                <Link to="/" className={classes.inquiryLinks}>
                  General inquiry
                </Link>
                <Link to="/" className={classes.inquiryLinks}>
                  Start Project
                </Link>
                <Link to="/" className={classes.inquiryLinks}>
                  Career
                </Link>
              </Box>
            </Grid>
          </Grid>

          <Hidden only="xs">
            <Box mt={10}></Box>
          </Hidden>
          <Grid container>
            <Hidden smUp>
              <Grid item xs={12}>
                <iframe
                  className={classes.iframe}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.887918424378!2d73.1744510968392!3d22.309576143809075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb4b6f80f42a1f8a!2sDhrup%20IT%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1569230992771!5m2!1sen!2sin"
                  width="90%"
                  height="400"
                  frameBorder="0"
                  title="Map"
                ></iframe>
              </Grid>
            </Hidden>
            <Grid item sm={5} xs={12}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <img src={address} alt="address" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Vadodara, India"
                    secondary="301/302 3rd Floor,  Yogi Complex, Alkapuri, Vadodara, GJ 390007,  India"
                  />
                </ListItem>
              </List>
              <Grid container>
                <Grid item md={6} sm={12} xs={6}>
                  <Box component="h4" mb={1}>
                    Email
                  </Box>
                  <a
                    className={classes.contactLinks}
                    href="mailto:hello@dhrup-com-948446.hostingersite.com"
                  >
                    hello@dhrup-com-948446.hostingersite.com
                  </a>
                  <a
                    className={classes.contactLinks}
                    href="mailto:business@dhrup-com-948446.hostingersite.com"
                  >
                    business@dhrup-com-948446.hostingersite.com
                  </a>
                </Grid>
                <Grid item md={6} sm={12} xs={6}>
                  <Box component="h4" mb={1}>
                    Call us
                  </Box>
                  <a
                    className={classes.contactLinks}
                    href="tel:(91) 479-642-7461"
                  >
                    Phone: (91) 479-642-7461
                  </a>
                </Grid>
              </Grid>
              <Box mt={5} className="inqLinks">
                <Link to="/" className={classes.inquiryLinks}>
                  General inquiry
                </Link>
                <Link to="/" className={classes.inquiryLinks}>
                  Start Project
                </Link>
                <Link to="/" className={classes.inquiryLinks}>
                  Career
                </Link>
              </Box>
            </Grid>
            <Hidden only="xs">
              <Grid item sm={7} xs={12} className="text-right">
                <iframe
                  className={classes.iframe}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.887918424378!2d73.1744510968392!3d22.309576143809075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb4b6f80f42a1f8a!2sDhrup%20IT%20Solutions%20Private%20Limited!5e0!3m2!1sen!2sin!4v1569230992771!5m2!1sen!2sin"
                  width="90%"
                  height="400"
                  frameBorder="0"
                  title="Map"
                ></iframe>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </div>
      {/* Start with Dhrup */}

      <div className={"contactForm " + classes.inquiry}>
        <Container>
          <Grid container>
            <Grid item md={8} sm={12}>
              <Typography variant="h3">Get started with Dhrup today</Typography>
              <p>
                Pick your impetus below, and Let's start with most powerful
                force available <Link to="/"> "Communication"</Link>
              </p>
              <form className={classes.container} noValidate autoComplete="off">
                <FormControl className={classes.formControl}>
                  <Select
                    value={values.query}
                    onChange={handleChange}
                    name="query"
                    className={classes.selectEmpty}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      className: "inquiryForm",
                      getContentAnchorEl: null
                    }}
                  >
                    <MenuItem value="Inquiry">General inquiry</MenuItem>
                    <MenuItem value="Schedule">Schedule Call</MenuItem>
                    <MenuItem value="Estimate">Estimate project</MenuItem>
                  </Select>
                </FormControl>
                <div hidden={values.Inquiry === false}>
                  <Grid container spacing={4}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="name"
                        label="Name"
                        className="textField"
                        variant="filled"
                        name="name"
                        value={name}
                        onChange={e => {
                          const val = e.target.value;
                          setName(prev => val);
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="phone"
                        label="Phone"
                        className="textField"
                        variant="filled"
                        name="phone"
                        value={phone}
                        onChange={e => {
                          const val = e.target.value;
                          setPhone(prev => val);
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TextField
                        id="email"
                        label="Email Address"
                        className="textField"
                        variant="filled"
                        type="email"
                        name="email"
                        value={email}
                        onChange={e => {
                          const val = e.target.value;
                          setEmail(prev => val);
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TextField
                        id="msg"
                        label="Message"
                        className="textField textarea"
                        variant="filled"
                        multiline
                        rowsMax="10"
                        name="Message"
                        value={message}
                        onChange={e => {
                          const val = e.target.value;
                          setMessage(prev => val);
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div hidden={values.Schedule === false}>
                  <Grid container spacing={4}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="Schedulename"
                        label="Name"
                        className="textField"
                        variant="filled"
                        name="name"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="Schedulphone"
                        label="Phone"
                        className="textField"
                        variant="filled"
                        name="phone"
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TextField
                        id="Schedulemail"
                        label="Email Address"
                        className="textField"
                        variant="filled"
                        type="email"
                        name="email"
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TextField
                        id="Schedulmsg"
                        label="Message"
                        className="textField textarea"
                        variant="filled"
                        multiline
                        rowsMax="10"
                        name="message"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div hidden={values.Estimate === false}>
                  <Grid container spacing={4}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="Estimatename"
                        label="Name"
                        className="textField"
                        variant="filled"
                        name="name"
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        id="Estimatephone"
                        label="Phone"
                        className="textField"
                        variant="filled"
                        name="phone"
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TextField
                        id="Estimateemail"
                        label="Email Address"
                        className="textField"
                        variant="filled"
                        type="email"
                        name="email"
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TextField
                        id="Estimatemsg"
                        label="Message"
                        className="textField textarea"
                        variant="filled"
                        multiline
                        rowsMax="10"
                        name="message"
                      />
                    </Grid>
                  </Grid>
                </div>
                <Box component="p" className={classes.termsText} mt={3}>
                  By clicking Submit, you agree our terms and that you have read
                  <br />
                  our<Link to="/Terms"> Terms &amp; Conditions</Link> policy
                  including our<Link to="/Policy"> Privacy Policy</Link>.
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={"btn " + classes.mt5}
                  onClick={getData}
                >
                  Submit
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Newsletter */}

      <Newsletter />

      {/* Consultation */}

      <Consultation
        title="We ensure the most delightful digital journey"
        content="let us test validation and scale growth channels for you so you can focus on other stuff."
        btnText="Enterprise"
      />
    </React.Fragment>
  );
}
